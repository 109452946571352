import React from 'react'
import title_image from '../src/assets/title.png'
import dc_ic from '../src/assets/dc_icon.png'
import twitter_ic from '../src/assets/twitter_icon.png'
import borderbottom from '../src/assets/borderbottom.png'
import clickme from '../src/assets/clickmearrow.png'

//image declarations
import a from '../src/assets/a.png'
import b from '../src/assets/b.png'
import c from '../src/assets/c.png'
import d from '../src/assets/d.png'
import e from '../src/assets/e.png'
import f from '../src/assets/f.png'
import g from '../src/assets/g.png'
import h from '../src/assets/h.png'
import i from '../src/assets/i.png'
import j from '../src/assets/j.png'
import k from '../src/assets/k.png'
import l from '../src/assets/l.png'
import m from '../src/assets/m.png'
import logo from '../src/assets/logo.png'

// import music from '../src/assets/music_bg.mp3'


const Content = () => {
  
  // const [playing, setPlaying] = React.useState(false);
  const [items] = React.useState([a,b,c,d,e,f,g,h,i,j,k,l,m])
  const [randomImage, setRandomImage] = React.useState(1)
  const change_img = () =>{
    

    if(randomImage >= 12){
      setRandomImage(1)
      document.getElementById("main_img").src = items[0];
    }
    else{
     setRandomImage(prev=> prev + 1)
      document.getElementById("main_img").src = items[randomImage];
    }
}

// React.useEffect(() => {
//   const audio = new Audio(music)
//   audio.currentTime = 0

//   playing ? audio.play() : audio.pause();
// }, [playing]);

  return (
    <div className="App">
    
    <div className='logo'>
      <a href='https://forms.gle/FbdiTjxJ7o1DwmUHA'target='_blank' rel='noreferrer'>
        <img src={logo} alt="logo"/>
      </a>
    </div>

   

    <header>
      <img src={title_image} alt="image_title"/>
        <div className='socials_container'>
        <a href='https://www.youtube.com/watch?v=xm3YgoEiEDc'> <img src={dc_ic} alt="dc icon"/></a> 
         <a href='https://twitter.com/BrokeBoysClub_' target='_blank' rel="noreferrer"> <img src={twitter_ic}alt="twitter icon"/></a>
      </div>
    </header>

    <section className='body' >
      <img src={items[0]} onClick={change_img} id ="main_img" alt='main_image'/>
      <img src={clickme} className="clickme" alt='click me arrow'/>
      <img className='image_border' src={borderbottom} alt="borderbottom"/>

      <div className='manifesto'>
        <h1>&lsquo;manifesto&rsquo;</h1>
        <br/>
        <p>roses are red, violets are blue.<br/> 
        crypto is down, our net worth too.<br/>
        we buy at highs, sell at lows.<br/>
        that's all a broke boy knows.<br/><br/>
        the dream is to make it, <br/>
        therefore, we must risk it for the biscuit.<br/>
        make sure to buy the dip<br/>
        even if the dip keeps dippin.<br/><br/>
        mistakes are sure to be made,<br/>
        so you better stay brave.<br/>
        the bear market will end, <br/>
        and soon we wil get this bread <br/><br/>
        no promises are made-<br/>
        all you need is a little bit of faith.<br/><br/>
        we are the broke boys club
        </p>
        
    </div>
    </section>

</div>
  )
}

export default Content